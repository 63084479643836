* {
    margin: 0;
    padding: 0;
}

/************************************ NAVIGATION *******************************************/
header {
    background-color: #000000;
    height: 100%;
    min-height: 80vh;
}

header nav li {
    list-style-type: none;
    margin-left: 20px;
}
header nav .nav-header-li {
    margin-top: 25px;
}
header nav .nav-header-li li{
    margin-top: 20px;
}

header nav a {
    display: flex;
    text-decoration: none;
    color: #fff;
}

header nav .nav-header-icon {
    font-size: 20px;
    margin-right: 10px;
}

header nav img{
    width: 130px;
    margin-top: 20px;
}

/********************************NAV FOOTER******************************/

footer {
    background-color: #181818;
    height: 15vh;
}

footer button {
    background-color: transparent;
    border: none;
    color: #ffffff;
    margin: 35px 25px 0 0;
    font-size: 25px;
    
}
audio{
  width: 60%;
  outline: none;
  border: none;
  border-radius: none;
}
audio::-webkit-media-controls-panel{
  background-color: #413b3b;
  border: none;
  outline: none;
  border-radius: none;
  color: #ffffff;
}
audio::-webkit-media-controls-timeline {
  background-color: #a7a7a7;
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
}
audio::-webkit-media-controls-volume-slider {
  background-color: #a7a7a7;
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}
audio::-webkit-media-controls-time-remaining-display {
  color: #ffffff;
}
audio::-webkit-media-controls-current-time-display {
  color: #ffffff;
}
audio::-webkit-media-controls-mute-button {
  background-color: #a7a7a7;
  border-radius: 50%;
}
audio::-webkit-media-controls-play-button{
  background-color: #a7a7a7;
  border-radius: 50%;
}

.footer-btn{
    display: flex;
    justify-content: center;
}

.footer-btn #icon-bc-radius  {
    background-color: rgb(255, 255, 255);
    padding: 10px 10px 4px 10px;
    border-radius: 50%;
    color: #000000;
}

  div[data-descr] { 
    position: relative; 
    text-decoration: underline; 
} 

div[data-descr]:hover::after { 
    content: attr(data-descr); 
    position: absolute; 
    left: 0; 
    top: -45px; 
    min-width: 150px; 
    border-radius: 4px; 
    background-color: #3f3f3f; 
    padding: 8px 8px; 
    color: #ffffff; 
    font-size: 13px; 
    z-index: 1; 
  }
/************************************ ALBUMS *******************************************/
.contain-buttons{
  display: flex;
  justify-content: space-between;
}
.list-albums{
  display: flex;
  overflow-x: scroll;
}
.album-img img{
  width: 100%;
  height: auto;
}

.album-img{
  width: 250px;
}
.album-name{
  color: white;
}
.list-albums::-webkit-scrollbar{
  display: none;
}
.container-album{
  display: flex;
  
}
.nav-bar{
  width: 20vw;
}
.right-part{
  width: 80vw;
  overflow-y: scroll;
  min-height: 80vh;
  height: 100%;
  overflow: scroll;
}
.container-main{
  height: 85vh;

}
.right-part::-webkit-scrollbar{
  display: none;
}
.container-main{
  background-color: #121212;
  display: flex;
}
.album{
  margin-right: 30px;
  background-color: #0C1014;
  padding: 20px;
  border: 2px solid;
  border-radius: 5px;
}
.container-album-info{
  color: white;
}
/* .track-item{
} */
.track{
  justify-content: space-between;
  display: flex;
  background-color: #3f3f3f;
  padding: 20px;
}
.track:hover{
  background-color: #4f4c4c;
}
/****************************Artist and Albums************************/
.button {
  width: 60px;
  height: 50px;
}
img{
  border-radius: 12px;
}
.contain-buttons{
  margin-bottom: 30px;
  margin-top: 30px;
}
/******************************Artist************************************/
.artist-all{
  display: flex;
  overflow-x: scroll;
}
.artist-all::-webkit-scrollbar{
  display: none;
}
.artist-artist{
  margin-right: 30px;
  background-color: #0C1014;
  padding: 20px;
  border: 2px solid;
  border-radius: 5px;
}
h2{
  color: white;
}
.artist-photo {
    height: 250px;
    width: 250px;
}
.artist-photo img {
    width: 100%;
    object-fit: cover;
    height: 100%
}
.container-artists-info{
  color: white;
  padding: 42px 422px 12px 42px;
}
/**************************Genre******************************************/
.gender-all{
    display: flex;
    overflow-x: scroll;
    width: 100%;
}
.gender-all::-webkit-scrollbar{
  display: none;
}
.gender-gender{
    margin-right: 70px;
    width: 250px;
    border: 1px solid black;
    background-color: #0C1014;
    color: rgb(254, 254, 254);
    padding: 10px 20px;
    height: 50px;
    margin-top: 80px;
    margin-bottom: 80px;
    
}
.gender-gender h2{
  width: 250px;
}
/******************************Research****************************/
.research {
  margin: 30px 0px 0px 30px;
  width: 50%;
  height: 50px;
  font-size: 20px;
  text-align: center;
  margin-bottom: 100px;
}
.search, .what{
  width: 20%;
  height: 50px;
  font-size: 20px;
  text-align: center;
}
.titre{
  color:white;
}
.artist-artist h2, .list-albums h2{
  margin-top: 30px;
  height: 30px;
  padding-bottom: 20px;
}
.flex{
  display: flex;
}